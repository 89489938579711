/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import LoadingDots from "../LoadingDots";
import auth from "../../services/auth";
import Navigation from "../Navigation";
import environment from "../../Environment";
import abilities from "../../services/abilities";
import Footer from "../Footer";

const ServiceRoute = ({ component: Component, isPrivate, ...rest }) => {
  if (isPrivate && !auth.isAuthenticated) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }

  return (
    <div>
      <QueryRenderer
        environment={environment}
        query={graphql`
          query ServiceRouteQuery($token: String!) {
            userByToken(token: $token) {
              id
              node_id
              usergroup
              name
              email
            }
          }
        `}
        variables={{
          token: localStorage.getItem("token")
        }}
        render={({ error, props: queryProps }) => {
          if (error) {
            return <div>{error.message}</div>;
          }
          if (queryProps) {
            // Check user's ability if they can visit this route or not
            document.title = `${rest.title} | SWRC Database`;
            if (
              abilities[queryProps.userByToken.usergroup].cannot(
                "goto",
                rest.path
              )
            ) {
              return (
                <Route
                  {...rest}
                  render={() => (
                    <Redirect
                      to={{
                        pathname: "/403",
                        state: { user: queryProps.userByToken }
                      }}
                    />
                  )}
                />
              );
            }
            // Return the Route, with nav & Container for styling
            return (
              <>
                <Navigation usergroup={queryProps.userByToken.usergroup} />
                <Container className="container-fluid Main">
                  <Route
                    {...rest}
                    render={props => (
                      <Component {...props} user={queryProps.userByToken} />
                    )}
                  />
                  <Footer />
                </Container>
              </>
            );
          }
          return (
            <LoadingDots
              loading
              color="#0089cf"
              size={20}
              className="LoadingScreen"
            />
          );
        }}
      />
    </div>
  );
};

export default ServiceRoute;
