import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../Environment";

const mutation = graphql`
  mutation LoginMutation($input: LoginInput!) {
    login(input: $input)
  }
`;
export default (email, password, callback) => {
  const variables = {
    input: {
      email,
      password
    }
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (data, errors) => {
      callback(data, errors);
    },
    onError: error => {
      callback(
        undefined,
        Array.isArray(error.codes) ? error.codes : ["networkerror"]
      );
    }
  });
};
