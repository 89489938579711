import React from "react";
import {
  Container,
  Navbar,
  // NavbarBrand,
  Nav,
  NavItem,
  Button
} from "reactstrap";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

import auth from "../../services/auth";
import "./Navigation.scss";

class Navigation extends React.Component {
  signOut = () => {
    auth.signout();
    const { history } = this.props;
    history.push("/login");
  };

  triggerSentry() {
    Sentry.captureException("Manual Feedback");
    Sentry.showReportDialog({
      title: "Report Bug",
      subtitle: "Please describe the issue that you're having below.",
      subtitle2: "",
      labelSubmit: "Submit"
    });
  }

  render() {
    return (
      <div className="DashboardNavigation">
        <Navbar light color="light">
          <Container>
            <a
              className="navbar-brand"
              href="https://www.saskwastereduction.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/img/logo.svg" alt="SWRC Database" />
            </a>
            <Nav navbar>
              <NavItem className="text-right">
                <Link to="/">Map</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/publish">Publish</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/recyclers">Recyclers</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/communities">Communities</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/locations">Locations</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/materials">Materials</Link>
              </NavItem>
              <NavItem className="text-right">
                <Link to="/templates">Templates</Link>
              </NavItem>
              <NavItem className="text-right">
                <Button onClick={this.signOut}>Sign Out</Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}

Navigation.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Navigation);
