import React from "react";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";

import "./LoadingDots.scss";

class LoadingDots extends React.PureComponent {
  render() {
    const { loading, className } = this.props;

    let loadingClass = "NotLoading";
    if (loading) {
      loadingClass = "Loading";
    }

    return (
      <div className={`PulseLoader ${className} ${loadingClass}`}>
        <PulseLoader loading={loading} color="#0089cf" size={20} />
      </div>
    );
  }
}

LoadingDots.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string
};

LoadingDots.defaultProps = {
  className: ""
};

export default LoadingDots;
