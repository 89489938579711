import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

Sentry.init({
  dsn:
    "https://e20ad8a9eb3c4111abcb40e32fb7400c@o529445.ingest.sentry.io/5647880",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  release: "database@1.0.x",
  tracesSampleRate: 1.0
});

ReactGA.initialize("G-S9S5B2D7GT");

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
