import React from "react";
import { FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import "./InputFeedback.scss";

export default function InputFeedback({ valid, children, className }) {
  return (
    <FormFeedback style={{ display: "block" }} className={className}>
      {valid === false ? children : <br />}
    </FormFeedback>
  );
}

InputFeedback.defaultProps = {
  valid: undefined,
  children: "",
  className: ""
};

InputFeedback.propTypes = {
  valid: PropTypes.oneOf([false, true, undefined]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string
};
