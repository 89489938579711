import React from "react";
import PropTypes from "prop-types";
import { Alert as AlertBase } from "reactstrap";

import "./Alert.scss";

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);

    setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { color, message } = this.props;
    const { visible } = this.state;
    return (
      <div>
        <AlertBase
          color={color}
          isOpen={visible}
          toggle={this.onDismiss}
          className="Alert"
        >
          {message}
        </AlertBase>
      </div>
    );
  }
}
Alert.defaultProps = {
  color: "success"
};

Alert.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default Alert;
