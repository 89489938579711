import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, FormGroup, Input, Label, Button } from "reactstrap";

import LoadingDots from "../../components/LoadingDots";
import Alert from "../../components/Alert";
import auth from "../../services/auth";
import LoginMutation from "../../mutations/LoginMutation";
import InputFeedback from "../../components/InputFeedback/InputFeedback";

import "./Login.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.emailInput = null;
    this.passwordInput = null;

    const { history, location } = this.props;

    // Display message once if it exists
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      errors: [],
      loading: false,
      alertMessage: null
    };
    if (props.location.state) {
      this.state.alertMessage = props.location.state.alertMessage;
      history.replace({
        pathname: location.pathname,
        state: {}
      });
    }
  }

  componentDidMount() {
    // Add event listeners because of autofill on iOS not bubbling
    this.emailInput.addEventListener("change", this.onChange);
    this.passwordInput.addEventListener("change", this.onChange);
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  onChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  login = () => {
    this.setState({ errors: [], loading: true });
    const { email, password } = this.state;
    LoginMutation(email, password, (data, errors) => {
      let redirectToReferrer = false;
      let newErrors = [];
      if (errors) {
        newErrors = errors;
      } else {
        auth.authenticate(data.login);
        redirectToReferrer = true;
      }
      this.setState({ loading: false, redirectToReferrer, errors: newErrors });
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/dashboard" } }; // eslint-disable-line

    const {
      redirectToReferrer,
      email,
      password,
      errors,
      loading,
      alertMessage
    } = this.state;

    if (redirectToReferrer) {
      if (typeof from !== "undefined") {
        return <Redirect to={from} />;
      }
      return <Redirect to="/dashboard" />;
    }

    return (
      <Container className="LoginScreen">
        <div>
          <h1 css={this.loaderCSS}>
            <img src="/img/logo.png" alt="SWRC" />
          </h1>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={this.onChange}
              onBlur={this.handleChange}
              onKeyPress={this.handleKeyPress}
              required
              innerRef={input => (this.emailInput = input)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={this.onChange}
              onBlur={this.handleChange}
              onKeyPress={this.handleKeyPress}
              required
              innerRef={input => (this.passwordInput = input)}
            />
          </FormGroup>
          <FormGroup>
            {errors.includes("doesnotexist") && (
              <InputFeedback valid={false}>
                This account does not exist, please contact SWRC for details.
              </InputFeedback>
            )}
            {errors.includes("unauthorized") && (
              <InputFeedback valid={false}>
                Your email and password combination are not valid.
              </InputFeedback>
            )}
            {errors.includes("networkerror") && (
              <InputFeedback valid={false}>
                There is trouble connecting to the server. Check your internet
                connection then try again.
              </InputFeedback>
            )}
          </FormGroup>
          <div>
            <LoadingDots loading={loading} />
          </div>
          <Button color="primary" className="mt-3" block onClick={this.login}>
            Login
          </Button>
        </div>
        {alertMessage && <Alert message={alertMessage} color="success" />}
      </Container>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Login;
