import React from "react";
import { Link } from "react-router-dom";
import auth from "../../services/auth";

import "./Footer.scss";

class Footer extends React.PureComponent {
  render() {
    return (
      <div className="Footer">
        <div className="Copyright">{`Saskatchewan Waste Reduction Council ${new Date().getFullYear()}`}</div>
        {!auth.isAuthenticated && <Link to="/login">Login</Link>}
      </div>
    );
  }
}

export default Footer;
