import React from "react";
import Loadable from "react-loadable";
import PulseLoader from "react-spinners/PulseLoader";

import "../App.scss";

const LoadingScreen = () => (
  <div className="PulseLoader LoadingScreen">
    <PulseLoader loading color="#0089cf" size={20} />
  </div>
);

export const Dashboard = Loadable({
  loader: () => import("../containers/Dashboard"),
  loading: LoadingScreen
});

export const Publish = Loadable({
  loader: () => import("../containers/Publish"),
  loading: LoadingScreen
});

export const Recyclers = Loadable({
  loader: () => import("../containers/Recyclers/Recyclers"),
  loading: LoadingScreen
});

export const RecyclerAdd = Loadable({
  loader: () => import("../containers/Recyclers/RecyclerAdd"),
  loading: LoadingScreen
});

export const RecyclerEdit = Loadable({
  loader: () => import("../containers/Recyclers/RecyclerEdit"),
  loading: LoadingScreen
});

export const Communities = Loadable({
  loader: () => import("../containers/Communities/Communities"),
  loading: LoadingScreen
});

export const CommunityAdd = Loadable({
  loader: () => import("../containers/Communities/CommunityAdd"),
  loading: LoadingScreen
});

export const CommunityEdit = Loadable({
  loader: () => import("../containers/Communities/CommunityEdit"),
  loading: LoadingScreen
});

export const Locations = Loadable({
  loader: () => import("../containers/Locations/Locations"),
  loading: LoadingScreen
});

export const LocationAdd = Loadable({
  loader: () => import("../containers/Locations/LocationAdd"),
  loading: LoadingScreen
});

export const LocationEdit = Loadable({
  loader: () => import("../containers/Locations/LocationEdit"),
  loading: LoadingScreen
});

export const Materials = Loadable({
  loader: () => import("../containers/Materials/Materials"),
  loading: LoadingScreen
});

export const MaterialAdd = Loadable({
  loader: () => import("../containers/Materials/MaterialAdd"),
  loading: LoadingScreen
});

export const MaterialEdit = Loadable({
  loader: () => import("../containers/Materials/MaterialEdit"),
  loading: LoadingScreen
});

export const Templates = Loadable({
  loader: () => import("../containers/Templates/Templates"),
  loading: LoadingScreen
});

export const TemplateAdd = Loadable({
  loader: () => import("../containers/Templates/TemplateAdd"),
  loading: LoadingScreen
});

export const TemplateEdit = Loadable({
  loader: () => import("../containers/Templates/TemplateEdit"),
  loading: LoadingScreen
});

export const Instruction = Loadable({
  loader: () => import("../containers/Instructions/Instruction"),
  loading: LoadingScreen
});

export const InstructionEdit = Loadable({
  loader: () => import("../containers/Instructions/InstructionEdit"),
  loading: LoadingScreen
});

export const Map = Loadable({
  loader: () => import("../containers/Map"),
  loading: LoadingScreen
});

export const FourOhFour = Loadable({
  loader: () => import("../containers/404"),
  loading: LoadingScreen
});

export const FourOhThree = Loadable({
  loader: () => import("../containers/403"),
  loading: LoadingScreen
});

export const FourHundred = Loadable({
  loader: () => import("../containers/400"),
  loading: LoadingScreen
});
