/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Container } from "reactstrap";
import { Route } from "react-router-dom";

import auth from "../../services/auth";

const PublicRoute = ({ component: Component, ...rest }) => {
  const administratorUser = {
    usergroup: "administrator"
  };
  const defaultUser = {
    usergroup: ""
  };
  if (rest.title == null) {
    document.title = "SWRC Database";
  } else {
    document.title = `${rest.title} | SWRC Database`;
  }
  return (
    <>
      {auth.isAuthenticated && (
        <>
          <Container className="MapViewContainer">
            <Route
              {...rest}
              render={props => (
                <Component {...props} user={administratorUser} />
              )}
            />
          </Container>
        </>
      )}
      {!auth.isAuthenticated && (
        <>
          <Container className="MapViewContainer">
            <Route
              {...rest}
              render={props => <Component {...props} user={defaultUser} />}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default PublicRoute;
