import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./containers/Login";
import ServiceRoute from "./components/ServiceRoute";
import ForceLogoutRoute from "./components/ForceLogoutRoute";
import PublicRoute from "./components/PublicRoute";
import AppBoundary from "./components/Boundary";
import {
  FourOhFour,
  FourHundred,
  FourOhThree,
  Dashboard,
  Recyclers,
  RecyclerAdd,
  RecyclerEdit,
  Communities,
  CommunityAdd,
  CommunityEdit,
  Locations,
  LocationAdd,
  LocationEdit,
  Materials,
  MaterialAdd,
  MaterialEdit,
  Templates,
  TemplateAdd,
  TemplateEdit,
  Map,
  Instruction,
  InstructionEdit,
  Publish
} from "./services/codeSplit";

import "./App.scss";

export default () => (
  <AppBoundary>
    <Router>
      <Switch>
        <ForceLogoutRoute path="/login" title="Login" component={Login} />
        <ServiceRoute
          title="Dashboard"
          path="/dashboard"
          component={Dashboard}
          exact
          isPrivate
        />
        <ServiceRoute
          title="Publish"
          path="/publish"
          component={Publish}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/recyclers"
          title="Recyclers"
          component={Recyclers}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/recycler/add"
          title="Add Recycler"
          component={RecyclerAdd}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/recycler/:id"
          title="Edit Recycler"
          component={RecyclerEdit}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/communities"
          title="Communities"
          component={Communities}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/community/add"
          title="Add Community"
          component={CommunityAdd}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/community/:id"
          title="Edit Community"
          component={CommunityEdit}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/locations"
          title="Locations"
          component={Locations}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/location/add"
          title="Add Location"
          component={LocationAdd}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/location/:id"
          title="Edit Location"
          component={LocationEdit}
          exact
          isPrivate
        />
        <ServiceRoute
          title="Materials"
          path="/materials"
          component={Materials}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/material/add"
          title="Add Materials"
          component={MaterialAdd}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/material/:id"
          title="Edit Materials"
          component={MaterialEdit}
          exact
          isPrivate
        />
        <ServiceRoute
          title="Templates"
          path="/templates"
          component={Templates}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/template/add"
          title="Add Template"
          component={TemplateAdd}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/template/:id"
          title="Edit Template"
          component={TemplateEdit}
          exact
          isPrivate
        />
        <PublicRoute
          path="/search/community/:communitySlug"
          title="Community Search"
          component={Map}
          exact
        />
        <PublicRoute
          path="/search/material/:materialSlug*"
          title="Material Search"
          component={Map}
          exact
        />
        <PublicRoute
          path="/search/community/:communitySlug/material/:materialSlug*"
          title="Search"
          component={Map}
          exact
        />
        <PublicRoute
          path="/search/location/:locationSlug*"
          title="Location"
          component={Map}
          exact
        />
        <ServiceRoute
          path="/:section/instructions/edit"
          title="Edit Instructions"
          component={InstructionEdit}
          exact
          isPrivate
        />
        <ServiceRoute
          path="/:section/instructions"
          title="Instructions"
          component={Instruction}
          exact
          isPrivate
        />
        <PublicRoute title="Search" path="/search" component={Map} exact />
        <PublicRoute path="/" component={Map} exact />
        <PublicRoute title="Unauthorized" path="/403" component={FourOhThree} />
        <PublicRoute title="Error" path="/400" component={FourHundred} />
        <PublicRoute title="Not Found" path="/404" component={FourOhFour} />
      </Switch>
    </Router>
  </AppBoundary>
);
