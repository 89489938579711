import { AbilityBuilder } from "@casl/ability";

const abilities = {
  administrator: AbilityBuilder.define((can, _cannot) => {
    can("view", "all");
    can("edit", "all");
    can("delete", "all");
    can("goto", "all");

    // Navigation
    can("view", "navigation");

    // Edit
    can("edit", "everything");
  }),
  default: AbilityBuilder.define((can, cannot) => {
    // This exists for 400&500 pages, as we aren't able to pass in the
    // usergroup as a prop, so we basically just allow them to navigate back to the
    // dashboard, where all of their abilities will be restored

    // Map
    can("view", "map");

    // Navigation
    cannot("view", "navigation");

    // Edit
    cannot("edit", "everything");
  })
};

export default abilities;
